 /* ---------- GOOGLE FONTS --------- */
/* DETAIL FONT */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
/* MAIN FONT */
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&family=Quicksand:wght@300..700&display=swap');

* {
 margin: 0;
 padding: 0;

 margin: 0 auto;
 max-width: 400px;
}

body {
  color: white;
  font-family: Mukta, Arial, Helvetica, sans-serif;
  background-color: #29292c;
}
/* ----------- HEADING ---------- */
.heading {
  margin: 1rem 5rem 1rem 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.header-icon{
  fill: red;
}

/* ----------- BANKROLL DETAILS ---------- */

.bankroll-details {
  display: flex;
  flex-direction: row;
  color: white
}

.bankroll-info-card {
  border-radius: 10px;
  width: 160px;
  text-align: center;
  padding: .5rem 0 .5rem;
  margin-bottom: 1rem;
  background: -webkit-linear-gradient(to right, #89216B, #DA4453);
  background: linear-gradient(to right, #89216B, #DA4453);



}

.bankroll-info-card p {
  font-size: large;
  background: transparent;
}

.bankroll-info-heading {
  font-weight: bold;
}

.bankroll-info-deatils {
  font-family: Quicksand;
  font-style: italic;
}

/* -----------Event Cards---------- */

.eventCard {
  /* border: 1px black solid; */
  max-width: 90vw;
  min-height: 60px;
  margin-top: 1rem;
  border-radius: 15px;
  /* background: linear-gradient(to right, #e9564b, #d21a2d); */

}

.eventCard-header {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: .4rem;
  margin-left: 1rem;
  gap: 1rem;
  font-family: Quicksand;
  font-size: small;
  color: #000000;
  background-color: transparent;
}

.eventCardTop {
  margin: .4rem;
  display: flex;
  flex-direction: row;
  font-size: large;
  font-weight: bold;
  align-items: center;
  background-color: transparent;
}

.eventCardTop-EventTitle {
  width: 11rem;
  margin: 0;
  margin-left: .75rem;
  background-color: transparent;
}
  
.eventCardTop-Buyin {
    width: 3rem;
    margin: 0 .4rem 0 0;
    margin-left: 0;
    text-align: center;
    background-color: transparent;
  }

  .eventCardBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
  }

  .eventCard-Payout-btn {
    background-color: #2cbc63;
  }
  
  .eventCardBtns button {
   margin: 0 .1rem;
   width: 30px;
   height: 30px;
   border-radius: 50%;
  }
  .eventCardBtns p {
    margin: 0 .5rem 0 .5rem;
    background-color: transparent;
  }

.eventCardBottom {
  margin: 0.5rem 0.5rem 0.5rem 0;
  display: flex;
  flex-direction: row;
  font-family: Quicksand;
  font-size: small;
  color: #000000;
  background-color: transparent;
}

.eventCardBottom-Labels {
  display: flex;
  flex-direction: row;
  gap: .3rem;
}

.eventcardProf {
  text-align: center;
}

.eventcardProf span {
  font-family: Quicksand;
  font-size: small;
  font-style: italic;
  color: #000000;
}

.lock {
  margin: 0 .1rem;
   width: 30px;
   height: 30px;
   border-radius: 50%;
   background-color: rgb(58, 40, 40);
  }

  .lock-icon {
    fill: white;
    color: white;
  }


/* ----------- Pop Up Window ---------- */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 75vw;
  height: 25vh;
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup h3 {
  color: #000000;
  margin-bottom: 20px;
}

.popup input {
  margin-bottom: 20px;
  padding: 10px;
  width: 75%;
}

.popup button {
  margin: 5px;
  padding: 10px 20px;
}

.popup-update {
  background-color: #2cbc63;
  font-weight: bold;
}

.popup-close {
  background-color: #ff0000b5;
  font-weight: bold;
}

